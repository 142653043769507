import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Uploader from "../components/Uploader";
import Autocomplete from "@mui/material/Autocomplete";
import { config } from "../constants";
import TitleBar from "../components/TitleBar";
import DeleteDialog from "../components/DeleteDialog";

function getToday() {
  const todaysDt = new Date();
  return todaysDt.toISOString().split("T")[0];
}


function clearFields() {
  const el = document.getElementById("toast");
  el.innerHTML = "";
}

function getNamesFromData(data) {
  const retArr = [];
  data.forEach((element) => retArr.push(element.paid_by));
  const uniqueArr = [...new Set(retArr)];

  return uniqueArr;
}

function receiptClick(address) {
  address = "https://split-z.com/receipts/" + address;
  window.open(address, "_blank");
}

export default function SharedBill() {
 
  let params = useParams();
  let billid = params.billid;

  const sample = {
    email: "",
    bill_name: "",
    items: [],
  };

  const [billdata, SetBillData] = useState(sample);

  const [uploadFile, SetUploadFile] = useState();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [rowToDelete, setRowToDelete] = useState();

  const handleClickOpen = (rowId) => {
    setRowToDelete(rowId);
    setOpenDeleteDialog(true);
  };

  useEffect(() => {
    fetch(config.API + "shared-bill?id=" + billid)
      .then((response) => response.json())
      .then((data) => SetBillData(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    const newFormData = new FormData(event.currentTarget);
    const item = {
      id: crypto.randomUUID(),
      description: newFormData.get("description"),
      date_paid: newFormData.get("date_paid"),
      paid_by: newFormData.get("paid_by"),
      amount: Number(newFormData.get("amount")),
      receipt: uploadFile,
    };

    const newData = {
      email: billdata.email,
      bill_name: billdata.bill_name,
      items: [...billdata.items, item],
    };

    SetBillData(newData);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newData),
    };

    fetch(config.API + "shared-bill?id=" + billid, requestOptions);

    SetUploadFile();
    clearFields();
  };

  return (
    <Container maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TitleBar cBillData={billdata} cSetBillData={SetBillData} />

        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "#C7CDD3",
          }}
        >
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Description</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Date</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Paid By</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Amount</TableCell>
                <TableCell sx={{ fontWeight: 700 }} align="center">
                  Receipt
                </TableCell>
                <TableCell sx={{ fontWeight: 700, width: 100 }} align="center">
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billdata.items.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.description}
                  </TableCell>
                  <TableCell>{row.date_paid}</TableCell>
                  <TableCell>{row.paid_by}</TableCell>
                  <TableCell>{row.amount.toFixed(2)}</TableCell>
                  <TableCell align="center">
                    {row.receipt ? (
                      <Tooltip title="View Receipt Image">
                        <IconButton onClick={() => receiptClick(row.receipt)}>
                          <ReceiptIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleClickOpen(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                     <DeleteDialog openDeleteDialog={openDeleteDialog} setOpenDeleteDialog={setOpenDeleteDialog} rowToDelete={rowToDelete} billdata={billdata} SetBillData={SetBillData} billid={billid}/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Grid
        container
        component="form"
        alignItems="center"
        onSubmit={handleSubmit}
        noValidate
        spacing={2}
        sx={{
          marginTop: 2,
        }}
      >
        <Grid item>
          <TextField
            margin="none"
            required
            id="description"
            label="Description"
            name="description"
            autoFocus
          />
        </Grid>
        <Grid item>
          <TextField
            id="date_paid"
            name="date_paid"
            label="Date Paid"
            type="date"
            defaultValue={getToday()}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            freeSolo
            sx={{ width: 220 }}
            options={getNamesFromData(billdata.items)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Paid By"
                id="paid_by"
                name="paid_by"
                margin="none"
                required
              />
            )}
          />
        </Grid>
        <Grid item>
          <TextField
            margin="none"
            required
            id="amount"
            type="number"
            label="Amount"
            name="amount"
          />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={billdata.email === "" ? true : false}
            endIcon={<AddCircleOutlineIcon />}
          >
            Add
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Uploader
            upload={(filename) => SetUploadFile(filename)}
            disable_dz={billdata.email === "" ? true : false}
          />
        </Grid>


      </Grid>
      <span className="upload-warning">
            Do not upload any confidential info e.g. credit card, bank account
            numbers
            <br />
          </span>
    </Container>
  );
}
