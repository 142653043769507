import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { config } from "../constants";

export default function DeleteDialog({openDeleteDialog, setOpenDeleteDialog, rowToDelete, billdata, SetBillData, billid}) {
   
      const handleClose = () => {
        setOpenDeleteDialog(false);
      };
    
      const handleCloseYes = (event) => {
        console.log("deleting..")
        console.log(rowToDelete)
        setOpenDeleteDialog(false);
       deleteRowFromTable(rowToDelete);
      };

      const deleteRowFromTable = (rowId) => {

        const newData = {
          email: billdata.email,
          bill_name: billdata.bill_name,
          items: billdata.items.filter((x) => x.id !== rowId),
        };
    
        SetBillData(newData);
    
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newData),
        };
    
        fetch(config.API + "shared-bill?id=" + billid, requestOptions);
      };
    


  return (
    <Dialog
      open={openDeleteDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete Item?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          No
        </Button>
        <Button onClick={handleCloseYes}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}
