import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { config } from "../constants";
import Copyright from "../components/Copyright";


export default function CreateNew() {
  let navigate = useNavigate();

  function navTo(data_json){
    const id = data_json['id'];
    navigate(`/shared-bill/${id}`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const createData = new FormData(event.currentTarget);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: createData.get('email'), bill_name: createData.get('desc'), items: [] })
  };
  fetch(config.API + 'create', requestOptions)
      .then(response => response.json())
      .then(data => navTo(data));
      
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

        <Typography component="h1" variant="h5">
          Create New Shared Bill
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoFocus
            autoComplete="email"
          />
        <TextField
            margin="normal"
            required
            fullWidth
            id="desc"
            label="Description"
            name="desc"

    
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
           Create
          </Button>

        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
