import React from "react";
import Home from "./pages/Home";
import SharedBill from "./pages/SharedBill";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CreateNew from "./pages/CreateNew";

const theme = createTheme({
  palette: {
    background: {
      default: "#FBFCFF",
    },
    primary: {
      main: "#274156",
    },
    secondary: {
      main: "#312756",
    },
  },
});

export default function App() {
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/shared-bill/:billid" element={<SharedBill />} />
            <Route path="/create" element={<CreateNew />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </CookiesProvider>
  );
}
