import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CalcOwed({ data }) {
  const namesList = [];

  let total = 0;
  data.forEach((element) => namesList.push(element.paid_by));
  data.forEach((element) => (total += element.amount));

  const namesSet = [...new Set(namesList)];

  const totalEach = total / namesSet.length;

  const namePaidDict = {};
  namesSet.forEach((named) => (namePaidDict[named] = 0));

  data.forEach((element) => (namePaidDict[element.paid_by] += element.amount));

  const nameOwedDict = {};
  namesSet.forEach((named) => (nameOwedDict[named] = totalEach));

  const debtDict = {};
  namesSet.forEach(
    (named) => (debtDict[named] = nameOwedDict[named] - namePaidDict[named])
  );

  let returnHtml = "";

  namesSet.forEach((named) => {
    let amt = debtDict[named];
    let verb = amt < 0 ? " is owed " : " owes ";

    returnHtml += named + verb + Math.abs(amt.toFixed(2)) + "\n";
  });

  return returnHtml;
}

export default function CalcModal({ openModal, setOpenModal, cBillData }) {
  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          List of Outstanding
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, whiteSpace: 'pre-line' }}>
          <CalcOwed data={cBillData.items} />
        </Typography>
        <br /><br />
        <span className="upload-warning">Hint: To split with more people add them with zero amounts</span>
      </Box>
    </Modal>
  );
}
