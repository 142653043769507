import React from "react";
import Dropzone from "react-dropzone-uploader";
import "../styles/dropzone.css";

export default function Uploader({upload, disable_dz}){

  const toast = (innerHTML) => {
    const el = document.getElementById("toast");
    el.innerHTML = innerHTML;
    el.className = "show";
    setTimeout(() => {
      el.className = el.className.replace("show", "");
    }, 3000);
  };

  const getUploadParams = () => {
    return {
      url: "https://split-z.com/api/upload",
    };
  };


  const handleChangeStatus = ({ meta, remove, xhr }, status) => {
    if (status === "done") {
      toast(`${meta.name} ...uploaded`);
      upload(xhr.response);
      remove();
    } else if (status === "aborted") {
      toast(`${meta.name}, upload failed...`);
    }
  };

  return (
    <React.Fragment>
      <div id="toast"></div>
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        accept="image/jpg,image/jpeg,image/png,application/pdf"
        maxFiles={1}
        maxSizeBytes={5242880}
        disabled={disable_dz}
        multiple={false}
        canCancel={false}
        inputContent="Drop A Receipt or Click to Browse (Image/PDF 5mb max)"
        styles={{
          dropzone: { width: "100%", height: 20, overflow: "overlay" },
          dropzoneActive: { borderColor: "green" },
        }}
      />
    </React.Fragment>
  );
};

