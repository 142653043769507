import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import { config } from "../constants";
import { useParams } from "react-router-dom";
import FunctionsIcon from "@mui/icons-material/Functions";
import Button from "@mui/material/Button";
import CalcModal from "./CalcModal";

export default function TitleBar({ cBillData, cSetBillData }) {

  let params = useParams();
  let billid = params.billid;

  const showIcon = () => {
    document.getElementById("titleEdit").style.display = "inline";
  };
  const hideIcon = () => {
    document.getElementById("titleEdit").style.display = "none";
  };

  const updateBillName = (newName) => {
    const newData = {
      email: cBillData.email,
      bill_name: newName,
      items: cBillData.items,
    };

    cSetBillData(newData);
  };

  const updateBillNameBack = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(cBillData),
    };

    fetch(config.API + "shared-bill?id=" + billid, requestOptions);
  };

  const [isNameFocused, setIsNamedFocused] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "30px",
      }}
 
    >
      <div
        id="titleAndIconContainer"
        style={{
          display: "flex",
          paddingLeft: "10px"
        }}
        onMouseOver={!isNameFocused ? showIcon : undefined}
        onMouseOut={!isNameFocused ? hideIcon : undefined}
      >
        <div>
          {!isNameFocused ? (
            <Typography
              component="h1"
              variant="h5"
              onClick={() => {
                setIsNamedFocused(true);
              }}
            >
              {cBillData.bill_name}
            </Typography>
          ) : (
            <TextField
              autoFocus
              component="h1"
              variant="standard"
              value={cBillData.bill_name}
              onChange={(event) => updateBillName(event.target.value)}
              onBlur={(event) => {
                setIsNamedFocused(false);
                hideIcon();
                updateBillNameBack();
              }}
            />
          )}
        </div>
        <div id="titleEdit" style={{ paddingLeft: "10px", display: "none" }}>
          <EditIcon />
        </div>
      </div>

      <div id="calcButton" style={{ paddingRight: "10px"}}>
        <Button
          color="primary"
          variant="contained"
          endIcon={<FunctionsIcon />}
          onClick={() => setOpenModal(true)}
        >
          Settle
        </Button>

        <CalcModal openModal={openModal} setOpenModal={setOpenModal} cBillData={cBillData}/>
      </div>
    </div>
  );
}
