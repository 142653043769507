import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { config } from "../constants";
import Copyright from "../components/Copyright";
import { useCookies } from "react-cookie";

export default function SignIn() {
  let navigate = useNavigate();

  const [cookie, setCookie] = useCookies();

  let initialBill = "";
  let initialChecked = false;

  if (cookie["splitz"] != null && cookie["splitz"].remember) {
    initialBill = cookie["splitz"].bill_id;
    initialChecked = true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const bill = data.get("billid");

    const cookie_data = {
      remember: data.get("remember") ? true : false,
      bill_id: bill,
    };

    setCookie("splitz", cookie_data, {
      path: "/",
      maxAge: 15780000,
      secure: true,
    });

    navigate(`/shared-bill/${bill}`);
  };

  const [forgotEmail, setForgotEmail] = useState();

  const handleTextFieldChange = (event) => {
    setForgotEmail(event.target.value);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleLostClickOpen = () => {
    setDialogOpen(true);
  };

  const handleLostClose = () => {
    setDialogOpen(false);
  };

  const handleLostRetrieveClose = () => {
    setDialogOpen(false);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: forgotEmail }),
    };
    fetch(config.API + "forgot", requestOptions);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{ m: 1, bgcolor: "primary.main" }}
          src="/images/splitz.png"
        />
        <Typography component="h1" variant="h5">
          Find a Shared Bill
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="billid"
            label="Shared Bill Id"
            name="billid"
            autoFocus
            defaultValue={initialBill}
          />
          <FormControlLabel
            control={<Checkbox defaultChecked={initialChecked} value="remember" color="primary" />}
            label="Remember"
            id="remember"
            name="remember"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Go to splitz
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={handleLostClickOpen}>
                {"Forgot shared bill Id?"}
              </Link>
              <Dialog open={dialogOpen} onClose={handleLostClose}>
                <DialogTitle>Forgotten Shared Bill Id</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please enter the email address associated with your shared
                    bill
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="email_forgot"
                    name="email_forgot"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="standard"
                    onChange={handleTextFieldChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleLostClose}>Cancel</Button>
                  <Button onClick={handleLostRetrieveClose}>Retrieve</Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item>
              <Link href="/create" variant="body2">
                {"Create new shared bill"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
